#mag-add{display: grid;grid-template-columns:49.5% 50%;grid-gap: 5px;align-items: center;}
#mag-add-abo{display: grid;grid-template-columns:49.5% 49.5%;grid-gap: 5px;align-items: center;}
#mag-add1{display: grid;grid-template-columns:24% 25% 25% 24%;grid-gap: 5px;align-items: center;}
#mag-add-row{display: grid;grid-template-columns:35% 35% 29%;grid-gap: 5px;align-items: center;padding:0px 5px;}
#mag-add-row1{display: grid;grid-template-columns:33% 33% 33%;grid-gap: 5px;align-items: center}
#note{width:60%}
#mag-expended{width: auto;height: auto;display: grid;grid-template-columns: repeat(auto-fill, minmax(180px,1fr));
              grid-gap: 5px;align-items: center;
              background-color: rgb(247, 247, 247); border-radius:5px;padding:8px;margin-left: auto;margin-right: auto;
            }
#mag-expended-row{display: flex;align-items: center;grid-gap: 5px;}
#mag-infos .MuiTab-root {
    padding: 6px 15%;
    max-width:33%;
}
#mag-infos .MuiTab-textColorInherit.Mui-selected {
    opacity: 1;
    color: var(--primary-main);
}
#mag-infos .PrivateTabIndicator-colorSecondary-114 {
    background-color: var(--primary-main);
}
#mag-infos .PrivateTabIndicator-colorSecondary-151 {
    background-color: var(--primary-main);
}
#mag-info-usr{height: 250px;overflow-y: auto;}
/*#param-val{margin-left: auto;margin-right: auto;}*/

#stat-mag{width: 95%;margin-top:9px;margin-left: auto;margin-right: auto;}
#stat-mag #row-1{  grid-gap: 10px; grid-template-columns: 49% 50%;margin-bottom: 5px;display: grid;}
#stat-mag #row-2{  grid-gap: 10px; grid-template-columns:49% 50%;display: grid;}

.content-editable-param {color:unset;padding: 0px 10px 0px 10px;text-align: start !important;width:auto;
    background: var(--secondary-dark);background: url('../img/edit.png') no-repeat right;background-size:10px ;
    height: auto;min-width: 150px; background-color:var(--main);border-radius: 3px;margin-left:3px ;grid-gap: 5px;} 
.content-editable-param:focus {
    background-color: var(--primary-dark);
    color: white;
    /*outline-color:blue;*/
    border: 1px solid var(--primary-dark);
    border-radius: 5px;
    outline: 0;
}

#infos-log{width:80%;margin: auto;text-align: center;}
#param-val .dtqzBx div:first-child {
    white-space: wrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    height: fit-content !important;
}
#diag-usr{margin-left: 3px;margin-bottom: 5px;}

#info-divs{display: grid;grid-template-columns: 49% 49%;justify-content: center;grid-gap: 15px;}
#info-d{align-items:center ;}
.MuiTabs-indicator{
    background-color: var(--primary-dark) !important;
}

#mag-expended{
     width:95%;margin-left:auto;margin-right: auto;background-color: var(--secondary-light);padding: 10px;display: grid;
     margin-bottom: 5px;grid-template-columns: 49% 49%;
}
#mag-expended #mag-expended-info{
    align-items: center;display: grid;grid-gap: 5px;grid-template-columns: 5% 28% 60%;
  }
#ctrlA #row-1{display: grid;grid-template-columns: 49% 49%;grid-gap: 10px;}
#ctrlA #row-2{display: grid;grid-template-columns: 33% 32% 32%;grid-gap: 10px;}

@media only screen and (min-width: 150px) and (max-width: 850px) {
    #mag-expended{align-content: center !important;}
    #mag-add,#mag-add1,#mag-add-row1,#mag-add-row{display: grid;grid-template-columns: repeat(auto-fit, minmax(160px,1fr));justify-content: center;grid-gap: 5px;}
    #note{width:100%}
    #mag-infos .MuiTab-root {padding: 6px 15%;max-width:45%;}
}