/* @import "theme.css";
@import "sprite.css";
@import "validator.css";
@import "ext/livedit.css"; */
body{
   background-color: #FFF!important;
   font-family: "Varela Round", sans-serif;}
div#global {
   width:100%;
   display:inline-block;
}
div#header,div#content,div#footer {
   /*max-width: 1400px;*/
   width: 100%;
   margin:auto;
}
div#header {
   height: 86px;
   top:0;
   background-image: url(../img/gplay.png);
}
div#logo {
   background: url(../img/logos/bq-b.png) no-repeat;
   width: 30px;
   height: 30px;
   background-size: 30px;
   display: inline-block;
   cursor: pointer;
}

div#menu {
   width: calc(100% - 280px);
   height: 30px;
   vertical-align: top;
   display: inline-block;
   /*font-weight: bold;*/
   padding: 5px 0 0 10px;
   white-space: nowrap;
   font-size: 17px;
}
div#menu ul {
   margin: 0;
   padding: 0;
   list-style: none;
   height: 31px;
}
div#menu ul li {
   position: relative;
   display: inline-block;
   list-style-type: none;
   overflow: hidden;
   height: 31px;
   margin-right: .6em;
}
div#menu ul li a {
   text-decoration: none;
   color:#3b3b3b;
   font-size: .8em;
   font-weight: normal;
}
div#menu ul li a:hover {
   color:#3b3b3b;
   font-weight: bold;
}
div#menu ul li a.selected::after {
   background-color:black;
   content: '';
   position: absolute;
   z-index: 5;
   left: 50%;
   bottom: -8px;
   margin-left: -11px;
   width: 16px;
   height: 16px;
   transform: rotate(45deg);
}
div#infos {
   display: inline-block;
   height: 35px;
   vertical-align: middle;
   padding-top: 0px;
   text-align: right;
   width: 230px;
}
div#infos label.mtn {
	color: #990000;
}
div#infos span#myCaiss {
	display: block;
	font-size: .7em;
	font-weight: bold;
	text-decoration: none;
	color: #626262;
	padding-right: 10px;
}
div#infos span#userName {
   font-size: .7em;
   font-weight: bold;
   color: black;
   text-decoration: none;
   margin-left: 4px;
   vertical-align: super;
   cursor:auto;
}
div#infos span#userName::after {
   color: black;
   font-weight: normal;
   
}
div#infos img#icoLogout {
   width: 15px;
   height: 15px;
   position: relative;
   top: 4px;
}
div#infos img#icoUser {
   width: 20px;
   height: 20px;
   position: relative;
   top: 3px;
}
div#infos form#f {
   display: inline-block;
   margin-bottom: 7px;
}
div#infos select {
   border: solid 1px #990000;
   font-size: .6em;
   width: 80px;
   vertical-align: super;
}
div#sMenu {
   width: 100%;
   height: 32px;
   background-color:black;
   display: grid;
   /*font-weight: bold;*/
   vertical-align: top;
   grid-template-columns:auto 160px;
}
#headbar{
   /*background-color: #990000;*/
   height:35px;
}
div#sMenu ul {
   margin: 0;
   list-style: none;
   height: 32px;
   padding-left: 20px;
}
div#sMenu #binars{
   display:flex;
   justify-content:right;
   align-items:center;
   color:white;
   padding-right:20px;
   grid-gap:5px
}
div#sMenu ul li {
   position: relative;
   display: inline-block;
   list-style-type: none;
   overflow: hidden;
   height: 32px;
   margin-right: .7em;
   color: white;
   padding: 3px 5px 9px 5px;
   box-sizing: content-box !important;
   font-weight: normal;
}
div#sMenu ul li a,.link2 {
   text-decoration: none;
   color: inherit;
   font-size: .9em;
}
div#sMenu ul li:hover {
   color:#9C9C9C!important;
}
div#sMenu ul li a.selected2::after {
   background-color: #e7e6e6;
   content: '';
   position: absolute;
   z-index: 1;
   left: 50%;
   bottom: 4px;
   margin-left: -11px;
   width: 16px;
   height: 16px;
   transform: rotate(45deg);
}
div#contentTitle {
   width: (100% - 10px);
   height: 18px;
   border-bottom: solid 1px #e7e6e6;
   padding-right: 10px;
   font-size: .9em;
   font-weight: bold;
   background-color:#e7e6e6;
   color: black;
   text-align: right;
   z-index: 1;
   position: relative;
}
.content {
   border-top: 0;
   border-bottom: 0;
   overflow: auto;
   display: block;
   height: calc(100% - 105px);
   margin-top: 0px;
   padding-left:0px;
   padding-right:0px;
   width: 100% ;
   top:86px;
   white-space: pre-line;
   position: fixed;
   bottom:19px;
}
div#main {
   width: calc(100% - 160px);
   height:calc(100% - 5px);
   display:inline-block;
   vertical-align:top;
}
div#marge {
   width:150px;
   height:calc(100% - 5px);
   /* background-color:rgba(128; 128; 128; 0.11); */
   display:inline-block;
}
div#footer {
   height: 16px;
   background: black;
   bottom:0;
   position: fixed;
   color: white;
   display: flex;
}
/* div#footer #logoDA{
   background: url(../img/logo_digitarts_boutiqa.png) no-repeat;
   width:100px;
   height:31px;
   margin-left: 5px;
   margin-top: 2px;
   background-size: 100px;
}
div#footer #logoLicence{
   background: url(../img/logo_iraha_white.png) no-repeat;
   width:70px;
   height:31px;
   margin-left: 5px;
   margin-top: 2px;
   background-size: 70px;
} */
div#footer span#copyright, div#footer span#lastMAJ, div#footer span#powered {
   font-size: .8em;
   /*font-weight: bold;*/
}
div#footer span#copyright{
   position: absolute;
   bottom: 0;
   left: 0;
   margin-left: 5px;
}
div#footer span#copyright1{
   width: 100%;
   height: 100%;
   position: absolute;
   bottom: 0;
   text-align: center;
   font-size: 11px;
}
div#footer span#lastMAJ{
   position: absolute;
   bottom: 0;
   right: 0;
   margin-right: 10px;
}
div#footer span#powered{
   position: absolute;
   bottom: 0;
   left: calc(50% - 140px);
}

/* mobile*/
.link {
   text-decoration: none;
   color: var(--primary-main);
   font-size: 1rem;
}

div#minfos {
   height: fit-content !important;
   width: fit-content !important;
   vertical-align: top;
   padding-top: 0px;
   text-align: right;
   align-self:right;
   flex-grow: 1;
   color:white;
}
div#minfos span#myCaiss {
	display: block;
	font-size: .7em;
	font-weight: bold;
	text-decoration: none;
	color:white;
}

div#minfos span#userName {
   font-size: .7em;
   font-weight: bold;
   color:white;
   text-decoration: none;
   margin-left: 4px;
   vertical-align: super;
}
div#minfos span#userName::after {
   content:'';
   color:white;
   font-weight: normal; 
}
div#minfos span#userName a {
   color:white;
   font-weight: normal; 
}
div#minfos img#icoLogout {
   width: 15px;
   height: 15px;
   position: relative;
   top: 4px;
   color:white;
}

.link2 {
   position: relative;
   list-style-type: none;
   overflow: hidden;
   padding: 0px;
   box-sizing: content-box !important;
}
.mselected2{
   color:#9C9C9C!important;
}
.changePassword{
   width:90%;
   height: fit-content;
   max-width:350px;
   margin: auto;
   padding: 30px 10px 10px 10px;
   background: var(--secondary-main);
   border: 1px solid var(--secondary-light);
   border-radius:5px;
   text-align: end;
}
#passValidator{
   width:95%;
   margin: auto;
   margin-bottom: 30px;
}
.changePassPage{
   padding:20px 2%;
}

#minfos .item1 { height:20px; grid-area: header; justify-content: right; }
#minfos .item2 { height:20px; grid-area: menu; }
#minfos .item3 { height:20px; grid-area: main; }
#minfos .item4 { height:20px; grid-area: right; }

 .logoutTemplate{
   display:grid;
   margin-top: 6px;
   grid-template:
   'header header header header header header header header header right'
   'menu menu menu menu menu menu menu menu menu menu'
   'main main main main main main main main main main ';
   grid-gap: 0px;
}

.mobileBigTitle{
   font-size: 1rem;
 }
.mobileSmallTitle{
   font-size: 0.9rem;
 }

 @media only screen and (min-width: 150px) and (max-width: 599px) {
   .content {
      top:67px;
      bottom:17px;
      height: calc(100% - 83px);
   }
}