.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
* {
  margin: 0;
  padding: 0;
      padding-right: 0px;
      padding-left: 0px;
}
*, :after, :before{
  box-sizing: border-box;
}
