
#usr #row-1{display:grid;grid-template-columns:83% auto;grid-gap: 10px;align-items: center;}
#usr-add{display: grid;grid-template-columns:49% 49%;grid-gap:10px}
.user-grid{display: grid;grid-template-columns:48% 48%;grid-gap:5px;align-items: center !important;}
#div-usr-hist{max-height: 400px;overflow: auto;}

#stk-usr{height: fit-content;overflow-y: auto;margin-top: 20px;max-height: 250px;}

@media only screen and (min-width: 150px) and (max-width: 850px) {
    #priv #row-1{grid-gap: 5px; grid-template-columns: repeat(auto-fit, minmax(160px,1fr));justify-content: center;}
    #role #row-1 {  grid-template-columns:repeat(auto-fit, minmax(160px,1fr));justify-content: center;grid-gap: 15px;}
  }
@media only screen and (min-width: 150px) and (max-width: 550px) {
    #usr #row-1{display:grid;grid-template-columns:repeat(auto-fit, minmax(180px,1fr)) !important;text-align: center;}
  }