/* @import "validator.css"; */
.login-page {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   margin: auto;
   box-sizing: border-box;
   background-color:#444343f7;
   overflow: hidden;
   text-align: center;
   font-family: "HelveticaW01-RoundedBd","Helvetica Neue","Helvetica","Arial","Microsoft YaHei New","Microsoft Yahei","SimSun","STXihei",sans-serif;
} 
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}
div.SingUpLoginBox {
   width:fit-content;
   margin:0 auto;
   text-align: center;
   /*justify-items: center;*/
}

div#logoDiv {
   background: url(../img/logo_text_bq.jpg) no-repeat;
   width: 300px;
   height: 300px;
   background-size: 300px;
   margin: 30px auto 0 auto;
}
div#loginDiv {
   background: url(../img/login_bq.jpg) no-repeat;
   background-size:400px;
   width:400px;
   height:190px;
   display: inline-block;
}
div#emailDiv {
   position: relative;
   top: 26px;
}
div#passDiv {
   position: relative;
   top: 60px;
   left: 120px;
   width: fit-content;
}
#divLogin{display:grid;grid-template-columns: 90% 10%;align-items: center;}
div#buttonDiv {
   position: relative;
   top: 80px;
   left: 60px;
   width:290px;
   height:45px;
}


#subo{
   width: 100%;
   height: 40px;
   background:transparent;
   border: none;
   cursor: pointer;
}

input#email {  margin-left: 50px; }
input#password { margin-left: 8px; }
input#email,input#password {
   background-size: 30px 30px;
   border: solid 0px #991915;
   font-size: 20px;
   color: black;
   padding-left:5px;
   background-position: 194px 1px;
   width: 190px;
   height: 25px;
   font-weight: bold;
}


div#logosDiv {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   height: 50px;
   width: 100%;
   margin: 0;
}
div#logosDiv2 div {
   margin: 0 15px;
}
div#logosDiv2 {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   height: 50px;
   width: 100%;
   margin: 0;
   margin-top: 10px;
}
div#bqDiv {
   background: url(../img/logos/mini_logo_bq_white.png) no-repeat;
   width: 40px;
   height: 40px;
   background-size: 40px;
   margin: 0 10px;
}
div#liberoDiv {
   background: url(../img/logos/logo_libero_v_white.png) no-repeat;
   width: 40px;
   height: 40px;
   background-size: 50px;
   margin: 0 10px;

}
div#webTelcoDiv {
   background: url(../img/logos/logo_WebTelco_white.png) no-repeat;
   width: 40px;
   height: 40px;
   background-size: 40px;
   margin: 0 10px;
}
div#irahtiDiv {
   background: url(../img/logos/logo_irahati_white.png) no-repeat;
   width: 95px;
   background-size: 100px;
   margin: 0 10px!important;
}
div#smartTelcoDiv {
   background: url(../img/logos/smart_telco_white.png) no-repeat;
   width: 80px;
   height: 40px;
   background-size: contain;
   margin: 0 0 0 10px;
}
div#smsOrDiv {
   background: url(../img/logos/SmsOr.png) no-repeat;
   width: 60px;
   height: 40px;
   background-size: contain;
   margin: 0 10px;
}
div#digitartsDiv {
   background: url(../img/logos/logo_digitarts_boutiqa.png) no-repeat;
   width: 150px;
   height: 40px;
   background-size:contain;
   margin: 0 30px 0 0;
}
div#partnerDiv {
   background: url(../img/logos/logo_iraha_white.png) no-repeat;
   width: 100px;
   height: 40px;
   background-size: contain;
}

div#authBottom {
   width: 100%;
   text-align: center;
   color:#f2f2f2;
   font-weight: bold;
   font-size: 14px;
   position: absolute;
   bottom: 3px;
}
#f1{
   width: 400px
}
.formContainer{
   width:400px;
   margin:0 auto;
}
@media only screen and (min-width: 150px) and (max-width: 600px) , (max-height: 610px) {
   #f1{
      width: 300px
   }
   .formContainer{
      width:300px;
      margin:0 auto;
   }
   div#loginDiv {
      background: url(../img/login_bq.jpg) no-repeat;
      background-size:300px;
      width:300px;
      height:150px;
      display: inline-block;
   }
   #divLogin{
     margin-bottom: 25px;
   }
   div#emailDiv {
      position: relative;
      top: 19px;
   }
   div#passDiv {
      position: relative;
      top: 38px;
      left: 80px;
      width: fit-content;
   }
   div#buttonDiv {
      position: relative;
      top: 49px;
      left: 45px;
      width:215px;
      height:32;
   }
   input#email,input#password {
      background-size: 30px 30px;
      border: solid 0px #991915;
      font-size: 20px;
      color: black;
      padding-left:5px;
      background-position: 194px 1px;
      width: 170px;
      height: 25px;
      font-weight: bold;
   }

   div#logosDiv {
      transform: scale(0.7, 0.7) translate(0, -60px);
      -ms-transform: scale(0.7, 0.7) translate(0, -70px); /* IE 9 /*/
   }
   div#logosDiv2  {
      transform: scale(0.7, 0.7) translate(0, -90px);
      -ms-transform: scale(0.7, 0.7) translate(0, -90px); /* IE 9 /*/
   }
}
#pwd-forgot{cursor: pointer;color: #f2f2f2;}
#sms-forgot{color:red;font-size: 13px;text-align: center;margin-bottom: 15px;}
#login-pwd{display: flex;align-items: center;grid-gap:10px;}
#pwd-for{
   position:absolute;
   top:415px;
   right:440px;
}

#changePass .MuiDialogContent-root:first-child {
    padding-top:10px !important; 
}
@media only screen and (max-width: 300px)  {
   .SingUpLoginBox{
    transform: scale(0.9) translate(-2%,0);
   }
}
@media only screen and (max-width: 800px) and (min-height: 1000px)  {
   .SingUpLoginBox{
    transform: scale(1.2) translate(0,20%);
   }

}
@media only screen and (min-width: 1000px) and (min-height: 1000px)  {
   .SingUpLoginBox{
    transform: scale(1.5) translate(0,30%);
   }
}
@media only screen and (max-height: 600px)  {
   .SingUpLoginBox{
    transform: scale(0.8) translate(0,-10%)
   }
}
@media only screen and (max-width: 1180px) and  (min-width: 1070px){
   #pwd-for{
      position:absolute;
      top:415px;
      right:290px
   }
}
@media only screen and (max-width: 1050px) and (min-width:850px){
   #pwd-for{
      position:absolute;
      top:415px;
      right:190px;
   }
}
@media only screen and (max-width: 849px)and (min-width:500px){
   #pwd-for{
      position:absolute;
      top:415px;
      right:150px;
   }
}
@media only screen and (max-width: 449px){
   #pwd-for{
      position:absolute;
      top:465px;
      right:150px;
   }
}